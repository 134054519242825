<template>
  <div class="pr">
    <div class="banner-box">
      <div class="uf">
        <div class="banner uf-f1 pr header-container self_bg">
          <div class="header-wrap">
            <div class="header-container">
              <!--轮播图 begin-->
              <div style="width: 100vw;height:100vh">
                <el-carousel :interval="5000" arrow="always" ref="carousel" class="wi100 hi100">
                  <el-carousel-item v-for="(item, index) in bannerLocal" :key="index" name="index">
                    <el-image class="wi100 hi100" :src="item" fit="cover"></el-image>
                    <div class="ban-dec uf uf-ver uf-pc">
                      <div class="dec-box">
                        <div class="dec-tit fwb">全行业、全产业链</div>
                        <div class="dec-dec">
                          覆盖全行业、打通全产业链，可以帮助政府和企业更好地了解市场趋势、竞争对手、消费者需求等信息，从而更好地掌握市场动态和趋势，从而制定更有效的战略和决策，提高企业的竞争力和盈利能力。
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <!--轮播图 end-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box mt-4 pt-2">
      <div class="any-box uf uf-ac uf-pj">
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="viewNum"/>
          <div class="mt-2 f16 fwb">访问量</div>
        </div>
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="serviceNum"/>
          <div class="mt-2 f16 fwb">服务项总数</div>
        </div>
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="orderNum"/>
          <div class="mt-2 f16 fwb">订单成交量</div>
        </div>
        <div class="a-list tac">
          <count-to class="c-base f30 fwb" :startVal="0" :endVal="userNum"/>
          <div class="mt-2 f16 fwb">注册用户数</div>
        </div>
      </div>

    </div>
    <div class="box1">
      <div class="content-box">
        <div class="pt-4">
          <div class="title-wrap uf uf-pc pr">
            <div class="title-icon"></div>
            <div class="title-name">十大行业</div>
            <div class="title-icon"></div>
          </div>
        </div>
        <div class="trades uf uf-ac uf-pj fwrap-w">
          <div class="t-list uf uf-ver uf-pc uf-ac c-white pr" :class="{on: index === curTradeIndex}" v-for="(item, index) in trades" :key="index" @mouseenter="curTradeIndex = index" @mouseleave="curTradeIndex=0">
            <i :class="`iconfont ${item.icon}`" :style="{color: item.color2}"></i>
            <div class="t-name">{{ item.name }}</div>
            <div class="dec-box">
              <div class="fwb tac">【服务场景】</div>
              <div class="txt1">{{ item.sence }}</div>
              <div class="fwb tac mt-2">【关键技术】</div>
              <div class="txt1">{{ item.tech }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box1 box3">
      <div class="uf uf-ver hi100">
        <div class="pt-4">
          <div class="title-wrap uf uf-pc pr">
            <div class="title-icon"></div>
            <div class="title-name">全产业链</div>
            <div class="title-icon"></div>
          </div>
        </div>
        <div class="flows uf uf-f1 uf-ac uf-pa">
          <div class="f-list uf uf-ver uf-pc uf-ac" v-for="(item, index) in flows" :key="index">
            <div class="icon-box uf uf-pc uf-ac">
              <i :class="`iconfont icon-${item.icon}`" :style="{color: item.color}"></i>
            </div>
            <div class="f-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box1 box4">
      <div class="content-box hi100 uf uf-ver">
        <div class="pt-4">
          <div class="title-wrap uf uf-pc pr">
            <div class="title-icon"></div>
            <div class="title-name">特色组件</div>
            <div class="title-icon"></div>
            <div class="cp more" @click="$router.push({name: 'search'})">查看全部<i class="el-icon-arrow-right ml-1"></i></div>
          </div>
        </div>
        <div class="components uf uf-ac uf-pj fwrap-w uf-f1">
          <div class="c-list uf uf-ac uf-pc" v-for="(item, index) in components" :key="index" @click="toService(item.id)">
            <div class="icon-box" :style="{background: item.color2}" >{{ item.name.split('')[0] }}</div>
            <div class="ml-3">
              <div class="f20 fwb">{{ item.name }}</div>
              <div class="mt-3 op-07">{{ item.company }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="mt-5 pt-4">-->
<!--      <div class="title-wrap uf uf-pc pr">-->
<!--        <div class="title-icon"></div>-->
<!--        <div class="title-name">需求大厅</div>-->
<!--        <div class="title-icon"></div>-->
<!--        <div class="cp more">查看全部<i class="el-icon-arrow-right ml-1"></i></div>-->
<!--      </div>-->
<!--      <div class="box2 uf uf-pj mt-3">-->
<!--        <div class="x-list tac" v-for="(item, index) in xqList" :key="index">-->
<!--          <el-image :src="item.url" style="width: 240px;height: 175px" fit="cover"></el-image>-->
<!--          <div class="fwb f16 mt-2">{{ item.name }}</div>-->
<!--          <div class="mt-2">-->
<!--            <el-tag class="mr-3" type="primary"><span class="mr-1">●</span>招标中</el-tag>-->
<!--            <el-tag type="primary"><span class="mr-1">●</span>长期有效</el-tag>-->
<!--          </div>-->
<!--          <div class="mt-3">-->
<!--            <el-button type="primary">立即对接</el-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <main-tools></main-tools>
  </div>
</template>

<script>
import MainTools from './main-tools'
import CountTo from 'vue-count-to'

export default {
  name: 'self-service',
  components: {
    MainTools,
    CountTo
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'login') {
      next(vm => {
        if (vm.unRead > 0) {
          vm.$notify.info({
            title: '消息提醒',
            message: '你有' + vm.unRead + '条未读消息',
            offset: 100
          })
        }
      })
    } else {
      next()
    }
  },
  data() {
    return {
      bannerLocal: [
        require('../../assets/img/banner1.jpg'),
        require('../../assets/img/banner2.jpg')
      ],
      planSearch: '',
      curTradeIndex: 0,
      trades: [
        {name: '生物医药行业', icon: 'icon-shengwuyiyao', color: '#409EFF', color2: '#9FDCFF', router: 'index', sence: '基于超算的药物筛选和研发。提供软件和算力支持的方式加快药物筛选、基因组学检测分析等进程。让以往需以月乃至年为计的运算、检测时间被缩短到几天，大大缩短药物研制时间，快速进入临床治疗。服务平台可基于超算中心的算力支撑，对接药物研发企业的研发能力和药物生产企业的生产能力。', tech: '基于超算的数字化服务；面向制造服务生态体系的数据交换方法'},
        {name: '机械装备行业', icon: 'icon-jixie', color: '#ff6900', color2: '#ffbd73', router: 'index', sence: '提供装备的智能运维服务。基于智能传感设备和智能边缘计算网关等实现对装备的实时监测与预警，提供从边缘计算到工业云平台端到端的服务，达到装备安全长周期运行维护的目的。使机械装备生产企业实现服务型制造转型。', tech: '基于AI+5G+IoT的云边端架构设备远程运维与故障诊断；工业海量数据管理与处理技术；面向全生命周期的制造服务数据治理。'},
        {name: '汽车行业', icon: 'icon-qiche', color: '#008638', color2: '#9cffd2', router: 'index', sence: '商用车全生命周期服务平台。例如陕西汽车“车轮滚滚”O2O服务平台，以整车产品为基础，在持续提升基本售后服务的同时，开展融资租赁、经营性租赁、商业保险、保险经纪、车联网数据服务等一系列增值服务业务。', tech: '产品服务系统设计与优化技术；制造服务智能交易技术；面向制造服务生态体系的数据交换方法。'},
        {name: '厨电行业', icon: 'icon-youyanji', color: '#9200e5', color2: '#d19fff', router: 'index', sence: '提供基于传感和人工智能技术的烹饪曲线模型。例如老板电器-中式烹饪曲线的建立。已开始，通过AI人工智能精准追踪时间和温度两大核心指标，掌控每道菜式的烹饪节奏，建设中式烹饪曲线数据库，形成烹饪曲线优化，模型进一步推动厨电的智能升级，提升用户体验。', tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；制造服务数据的安全隐私保护。'},
        {name: '纺织行业', icon: 'icon-duanxiujikouyifu', color: '#c5a500', color2: '#c9c579', router: 'index', sence: '提供纺织服装全产业敏捷供应链管理，实现产业链上下游敏捷协同。上游由供应商乃至供应商的供应商组成的企业合作伙伴关系，下游由生产商、分销商、零售商、终端消费者组成的用户关系。面向供应链的统一管理，促进信息流、资金流、物流的协同整合，提升供应链整体的效率和效益。', tech: '网络驱动的制造产品服务化设计；制造服务智能交易技术。'},
        {name: '包装行业', icon: 'icon-wuliaobaozhuangdan', color: '#409EFF', color2: '#9FDCFF', router: 'index', sence: '提供基于AI+AR的包装定制化设计服务。提供具有专业包装设计能力的人工智能和增强现实服务，客户只需在包装商城上输入包装设计需求，就会自动快速提供若干种方案供其选择，并且能呈现最终的三维立体、增强现实效果。具有强大的学习能力，学习用户习惯、市场偏好等等，这些都将成为它的“知识储备”，更好地为用户提供包装设计服务。', tech: '基于数字孪生和工业互联网的数字化服务技术；产品与服务系统设计优化方法。'},
        {name: '家具行业', icon: 'icon-shafa', color: '#ff6900', color2: '#ffbd73', router: 'index', sence: '提供易简大规模个性化定制服务模式，实现“先设计，再销售，后生产”。破解定制家居生产的周期长、出错率高、品质差和规模生产难的难题，实现了规模个性化设计、大规模个性化定制与大规模个性化服务，有效把制造与服务融合，为消费者提供一站式的全新的定制家居消费体验。', tech: '基于数字孪生和工业互联网的数字化服务技术；基于人工智能技术的制造和服务深度融合。'},
        {name: '生鲜食品行业', icon: 'icon-shuiguo',color: '#008638', color2: '#9cffd2', router: 'index', sence: '提供冷链物流全过程、全周期服务，实现冷链全过程监测与追溯，检验检疫服务。例如阿里的盒马鲜生。盒马运用大数据、移动互联、智能物联网、自动化等技术及先进设备，实现人、货、场三者之间的最优化匹配，平台拥有从供应链、仓储到配送的完整物流体系。', tech: '网络驱动的产品服务化设计；服务数据交换方法、定价模型和智能交易技术。'},
        {name: '电梯行业', icon: 'icon-dianti-xiantiao', color: '#9200e5', color2: '#d19fff', router: 'index', sence: '提供电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。例如，通过工业互联网的规则引擎与阿里云其他云产品打通，实现采集+计算+存储+AI+可信的全栈服务，充分挖掘物联网数据的价值，实现连接到智能的跨越。', tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；全生命周期的制造服务数据治理。'},
        {name: '精密模具行业', icon: 'icon-yewumoban', color: '#409EFF', color2: '#9FDCFF', router: 'index', sence: '采用“平台+小微”方式，实现“共享制造”。例如深圳市五鑫科技的“Mould Lao众创空间”，以赋能的方式帮助平台内小微企业及各个技术创客实现从被动劳动变成自主竞价模式下的技能共享。小微企业的每笔订单，以内部市场竞标的方式由小微及创客自主报价，相互竞标。', tech: '服务数据交换方法、定价模型和智能交易技术；网络驱动的协同制造技术。'}
      ],
      flows: [
        {name: '研发', icon: 'dengpao', color: '#409EFF', color2: '#2deeff'},
        {name: '设计', icon: 'sheji', color: '#ff6900', color2: '#ffbd73'},
        {name: '生产', icon: 'shengchan', color: '#008638', color2: '#9cffd2'},
        {name: '测试', icon: 'ceshi', color: '#9200e5', color2: '#d19fff'},
        {name: '销售', icon: 'walletY', color: '#c5a500', color2: '#c9c579'},
        {name: '物流', icon: 'wuliuxinxi', color: '#ff004c', color2: '#ff4288'},
        {name: '售后', icon: 'shouhou', color: '#409EFF', color2: '#9FDCFF'}
      ],
      components: [
        {name: '订单系统', color: '#409EFF', color2: '#2deeff', company: '山东新网科技有限公司', id: ''},
        {name: '派单系统', color: '#ff6900', color2: '#ffbd73', company: '山东新网科技有限公司', id: ''},
        {name: '生产系统', color: '#008638', color2: '#9cffd2', company: '山东新网科技有限公司', id: ''},
        {name: '测试系统', color: '#9200e5', color2: '#d19fff', company: '山东新网科技有限公司', id: ''},
        {name: '物流系统', color: '#ff004c', color2: '#ff4288', company: '山东新网科技有限公司', id: ''},
        {name: '代加工系统', color: '#409EFF', color2: '#9FDCFF', company: '山东新网科技有限公司'}
      ],
      xqList: [
        {name: '汽车物流运输提质增效', url: require('../../assets/img/index/img_3.jpg')},
        {name: '寻求马铃薯自动脱皮技术', url: require('../../assets/img/index/img_4.jpg')},
        {name: '光伏组件供应', url: require('../../assets/img/index/img_5.jpg')},
        {name: '寻求基于智能化、信息化的印刷..', url: require('../../assets/img/index/img_6.jpg')}
      ],
      sImg: require('../../assets/img/support1.png'),
      bannerList: [],
      fromLogin: false,
      config: {
        data: [
          '12313131',
          '3231kldksfks'
        ]
      },
      itemBodyStyle: {
        padding: '0',
        height: '120px'
      },
      newsBodyStyle: {
        padding: '0',
        height: '210px'
      },
      newsList: [],
      classOption: {
        step: 1,
        hoverStop: true,
        direction: 1,
        waitTime: 1000
      },
      serviceNum: 785,
      viewNum: 875201,
      orderNum: 12413,
      userNum: 9870
    }
  },
  computed: {
    name: {
      get() {
        return this.$store.state.user.name
      },
    },
    loginStatus: {
      get() {
        return this.$store.state.user.loginStatus
      },
      set(val) {
        this.$store.commit('user/updateLoginStatus', val)
      }
    },
    unRead: {
      get() {
        return this.$store.state.user.unRead
      }
    },
    enterpriseList: {
      get() {
        return this.$store.state.user.info
      },
      // set (val) { this.$store.commit('user/updateInfo', val) }
    },
    enterpriseId() {
      return this.enterpriseList[0] ? this.enterpriseList[0].id : ''
    }
  },
  watch: {
    enterpriseId(val) {
      this.initNews(val)
    }
  },
  created() {
    this.getCarousel()
    if (this.loginStatus) {
      this.initNews(this.enterpriseId)
    }
    this.initCommonParamNum()
  },
  methods: {
    toService (serviceId) {
      //
      this.$router.push({name: 'service', query: {id: serviceId, isProvider: false}})
    },
    // 公共数量
    initCommonParamNum(){
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.SEARCH.COMMON),
        method: 'GET',
        params: this.$http.adornParams({
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.serviceNum = data.data.serviceNum
          this.viewNum = data.data.viewNum
          this.orderNum = data.data.orderNum
          this.userNum = data.data.userNum
          if(data.data.serviceInfoList){
            this.components[0].name = data.data.serviceInfoList[0].serviceName
            this.components[0].id = data.data.serviceInfoList[0].id
            this.components[0].company = data.data.serviceInfoList[0].enterpriseName
            this.components[1].name = data.data.serviceInfoList[0].serviceName
            this.components[1].id = data.data.serviceInfoList[0].id
            this.components[1].company = data.data.serviceInfoList[0].enterpriseName
            this.components[2].name = data.data.serviceInfoList[0].serviceName
            this.components[2].id = data.data.serviceInfoList[0].id
            this.components[2].company = data.data.serviceInfoList[0].enterpriseName
            this.components[3].name = data.data.serviceInfoList[0].serviceName
            this.components[3].id = data.data.serviceInfoList[0].id
            this.components[3].company = data.data.serviceInfoList[0].enterpriseName
            this.components[4].name = data.data.serviceInfoList[0].serviceName
            this.components[4].id = data.data.serviceInfoList[0].id
            this.components[4].company = data.data.serviceInfoList[0].enterpriseName
            this.components[5].name = data.data.serviceInfoList[0].serviceName
            this.components[5].id = data.data.serviceInfoList[0].id
            this.components[5].company = data.data.serviceInfoList[0].enterpriseName
          }
        }
      })
    },
    initNews(val) {
      // this.$http({
      //   url: this.$http.adornUrl(this.$api.QCC.NEWS.LIST),
      //   method: 'GET',
      //   params: this.$http.adornParams({
      //     enterpriseId: val
      //   })
      // }).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.newsList = [...data['result']['news']]
      //   }
      // })
    },
    /**
     * 查询轮播图
     */
    getCarousel() {
      // this.$http({
      //   url: this.$http.adornUrl(this.$api.PUB.CAROUSEL),
      //   method: 'get',
      //   params: this.$http.adornParams()
      // }).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.bannerList = data.page.list
      //   }
      // })
    },
    setActiveItem(index) {
      this.$refs.carousel.setActiveItem(index)
    },
    toImplement(index) {
      if (index === 4) {
        this.$router.push({name: 'implement'})
      } else if (index === 5) {
        this.$router.push({name: 'search', params: {itemCode: '20'}})
      }
    },
    goPage(page = '') {
      this.$router.push({name: page})
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-carousel__arrow {
  width: 60px;
  height: 60px;
  font-size: 20px;
  background-color: rgba(31, 45, 61, .31);
}

::v-deep .el-carousel__arrow--right {
  right: 90px
}

::v-deep .el-carousel__arrow--left {
  left: 90px
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 100px;
}
.title-icon {
  width: 60px;
  height: 60px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255,255,255,.2);
  }

  &:after {
    content: '';
    position: absolute;
    top: 30px;
    left: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255,255,255,.2);
  }
}

.ban-dec {
  position: absolute;
  top: 100px;
  left: 260px;
  right: 260px;
  bottom: 130px;

  .dec-box {
    text-align: center;
    background: rgba(0, 0, 0, .3);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    color: white;
    padding: 30px;

    .dec-tit {
      font-size: 3vw;
      margin-bottom: 1vw;
    }

    .dec-dec {
      line-height: 35px;
      opacity: .7;
    }
  }
}

.title-name {
  font-size: 30px;
  font-weight: bold;
  padding: 10px 50px;
  color: white;
}

.content-box {
  width: 1300px;
  margin: 20px auto;
  position: relative;
  z-index: 10;

  .any-box {
    margin-top: -90px;
    margin-bottom: -100px;
    background: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border-radius: 8px;
    padding: 30px;
    //margin-bottom: 30px;
    .a-list {
      width: 30%;
      border-right: 1px solid #d0d0d0;

      &:last-child {
        border-right: none;
      }
    }
  }

  .title-wrap {
    color: white;
    .more {
      position: absolute;
      right: 0;
      top: 26px;
      color: #00b2ff;

      &:hover {
        color: #0089c7;
      }
    }
  }

}

.box1 {
  width: 100vw;
  height: 68vw;
  padding-top: 100px;
  background: url("../../assets/img/index/bg_3.jpg");
  background-size: cover;
  .trades {
    margin-top: 30px;
    .t-list {
      width: 24%;
      height: 260px;
      margin-bottom: 15px;
      background: rgba(0,0,0,.4);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      border-radius: 10px;
      cursor: pointer;
      border: 1px solid rgba(75, 212, 255, 0.5);
      overflow: hidden;
      &:first-child, &:last-child {
        width: 641px;
      }
      .iconfont {
        font-size: 60px;
      }
      .t-name {
        font-size: 26px;
        margin-top: 30px;
        font-weight: lighter;
      }
      .dec-box {
        position: absolute;
        top: -260px;
        left: 0;
        width: 100%;
        height: 260px;
        background: #000;
        padding: 10px;
        transition: all .3s ease;
        line-height: 26px;
        font-size: 12px;
        overflow-y: scroll;
        scrollbar-color:  #999 #dbdbdb;  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
        scrollbar-width: thin;  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
        -ms-overflow-style:none;  /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */

        /* 以下是chrome浏览器自定义滚动条样式方式 */
        &::-webkit-scrollbar {/*滚动条整体样式*/
          width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
          height: 12px;
        }
        &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
          border-radius: 12px;
          -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.1);
          background: #c2c2c2;
        }
        &::-webkit-scrollbar-track {/*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.1);
          border-radius: 12px;
          background: #ececec;
        }
        //color: #333333;
        .txt1 {
          color: #d0d0d0;
        }
      }
    }
    .on:hover {
      .dec-box {
        top: 0;
      }
    }
  }
  .b-list {
    width: 30%;
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.06);
    }

    .iconfont {
      font-size: 80px;
      background-image: linear-gradient(60deg, #00b2ff, #00ffe9);
      background-clip: text;
      color: transparent;
    }

    .txt {
      margin-top: 20px;
      font-size: 24px;
    }

    .dec {
      font-size: 14px;
      margin-top: 15px;
    }
  }
}
.box3 {
  height: 50vw;
  padding: 30px;
  background: url("../../assets/img/index/bg_4.jpg");
  background-position: center;
  .flows {
    .f-list {
      .icon-box {
        width: 7vw;
        height: 7vw;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 0 20px rgba(0,0,0,.2);
        .iconfont {
          font-size: 2.6vw;
        }
      }
      .f-name {
        font-size: 1vw;
        font-weight: bold;
        color: white;
        margin-top: 1vw;
      }
      &:nth-child(1) {margin-bottom: 2vw}
      &:nth-child(2) {margin-bottom: 10vw}
      &:nth-child(3) {margin-bottom: 6vw}
      &:nth-child(4) {margin-bottom: 0}
      &:nth-child(5) {margin-bottom: -4vw}
      &:nth-child(6) {margin-bottom: -4vw}
      &:nth-child(7) {margin-bottom: 8vw}
    }
  }
}
.box2 {
  .right {
    background: white;
    padding: 20px 30px;
    border-radius: 0 8px 8px 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);

    .n-list {
      line-height: 50px;
      border-bottom: 1px dashed #ababab;
      cursor: pointer;

      &:hover {
        color: #00b2ff;
      }
    }

    .s-list {
      width: 48%;
      border: 1px solid #eeeeee;
      padding: 10px;
      transition: all .3s ease;

      &:hover {
        background: #efefef;
      }
    }
  }

  .x-list {
    padding: 20px 0;
    width: 22%;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #00b2ff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}
.box4 {
  height: 40vw;
  margin-top: 0;
  padding-top: 30px;
  background: url("../../assets/img/index/bg_2.jpg");
  background-position: center;
  .components {
    .c-list {
      position: relative;
      width: 29%;
      margin-bottom: 20px;
      background: rgba(0,0,0,.5);
      padding: 40px 30px;
      color: white;
      border: 1px solid rgba(255,255,255,.5);
      cursor: pointer;
      .icon-box {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        font-size: 30px;
        line-height: 70px;
        text-align: center;
        color: #333333;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 3px;
        background: linear-gradient(90deg, #00eaff, #00ffbb);
        transition: all .5s ease;
      }
      &:hover:after {
        width: 100%;
      }
    }
  }
}
.header-container {
  /*margin-top: 10px;*/
  /*padding-top: 10px;*/
  display: flex;
  justify-content: center;
  /*padding-bottom: 40px;*/
  /*background-color: #FFFFFF;*/
  /*width: 1400px;*/
  /*height: 600px;*/
}

.self_bg {
  background: url("../../assets/img/self/self_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.warp {
  height: 180px;
  width: 100%;
  overflow: hidden;
}

.header-wrap {
  display: flex;
  justify-content: center;
}


.banner-box {
  width: 100%;
  min-width: 1300px;
  /*background-image: url("../../assets/img/self/self_background.png");*/
}

.main .banner .bg-img {
  width: 100vw;
  min-width: 1300px;
  height: 685px;
  background-size: 1920px 685px;
  background-position: center top;
}

.banner-box .preview {
  position: absolute;
  bottom: -40px;
  z-index: 11;
}

.banner-box .preview .wrap {
  width: 1000px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
}

.banner-box .mask {
  width: 100%;
  min-width: 1300px;
  height: 72px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 10;
}

.banner-box .mask span {
  width: 100%;
  min-width: 1200px;
  height: 72px;
  display: block;
  background: url("../../assets/img/mask-grey.png") no-repeat;
  background-size: 100% 72px;
  background-position: center top;
}


.banner-right {
  /*background-color: red;*/
  margin: .2rem;
  width: 300px;
  height: 602px;
  border-radius: 0px 8px 8px 0px;
  /*background-color: #4647BB;*/
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  /*justify-content: flex-end;*/
  /*background-color: #f0f0f0;*/
}

.ent-name {
  color: #409EFF;
}

.card-hover {
  /*padding: .1rem;*/
  height: 20%;
  cursor: pointer;
}


@media screen and (max-width: 1400px) {
  /*.banner-box >>> .el-carousel__arrow--left {*/
  /*  left: 22vw*/
  /*}*/
  /*.banner-box >>> .el-carousel__arrow--right {*/
  /*  right: 22vw*/
  /*}*/
}
</style>
